import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hImg1 from '../../images/slider/slide-4-carrara.jpg';
import hImg2 from '../../images/slider/slide-5.jpg';
import hImg3 from '../../images/slider/slide-6.jpg';

import shape from '../../images/slider/shape-img.jpg';


const Slide = [
    {
        id: '01',
        sImg: hImg1,
        Des: "Our personal approach helps us create functional and comfortable spaces, always keeping in mind each client's history and style.",
        Title: 'Make your House',
        Title2: 'Feel like Home',
    }
]


const Hero2 = () => {

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();


    return (
        <section className="wpo-hero-slider-1">
            <div className="hero-slider">
                <div className="slide">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-6 col-md-5 col-sm-12 slide-caption">
                                <Slider
                                    asNavFor={nav1}
                                    ref={(slider2) => setNav2(slider2)}
                                    slidesToShow={1}
                                    arrows={false}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                >

                                    {
                                        Slide.map((slide, sld) => (
                                            <div className="slide-item" key={sld}>
                                                <div className="slide-title">
                                                    <h2>{slide.Title} <br /> {slide.Title2}</h2>
                                                </div>
                                                <div className="slide-subtitle">
                                                    <p>{slide.Des}</p>
                                                </div>
                                                <div className="btns">
                                                    <a target="_blank" href="https://www.instagram.com/mvinterior.studio" className="theme-btn-s2">
                                                        Discover More
                                                    </a>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </Slider>
                            </div>
                            <div className="slider-pic">
                                <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} dots={true} arrows={false} fade={true}>
                                    {
                                        Slide.map((slide, sld) => (
                                            <div className="slider-item" key={sld}>
                                                <img src={slide.sImg} alt="" />
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <ul className="hero-social-icon">
                        <li><Link to="/">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Link></li>
                        <li><a target="_blank" href="https://www.instagram.com/mvinterior.studio">Instagram</a></li>
                        <li><Link to="/">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Link></li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Hero2;