import React from 'react'
import CountUp from 'react-countup';
import icon from '../../images/call.svg'
import abimg1 from '../../images/about-right-1-carrara.jpg'
import abimg2 from '../../images/about-right-2.jpg'
import { Link } from 'react-router-dom'


const About2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (
        <section className="Arkitek-about-section-s2 section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-12">
                        <div className="about-left-item">
                            <div className="wpo-section-title">
                                <span>About Us</span>
                                <h2>Transforming space into your dreams come true</h2>
                                <p>
                                Whether it's a simple room remodel or a full construction, 
                                I believe that every design endeavor presents a unique opportunity 
                                to bring a client's vision to life. 
                                Well-designed interiors can have a transformative effect on individuals, 
                                promoting productivity, relaxation, and overall well-being.
                                </p>
                            </div>
                            <div className="left-btn">
                                <a target="_blank" href="https://www.instagram.com/mvinterior.studio" className="theme-btn">
                                    Discover More
                                </a>
                                <Link onClick={ClickHandler} className="call" to="/contact">
                                    <div className="icon">
                                        <img src={icon} alt=""/>
                                    </div>
                                    <div className="text">
                                        <h5>Call Us</h5>
                                    </div>
                                </Link>

                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="about-right-item">
                            <div className="right-image-1">
                                <img src={abimg1} alt=""/>
                            </div>
                            <div className="right-text">
                                <div className="info">
                                    <h3><CountUp end={10} enableScrollSpy />+</h3>
                                    <p>Years of Experience
                                        In This Field</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <span id="counter" className='d-none' />
        </section>
    )
}

export default About2;