import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo-2-mv-4.png'
import Services from '../../api/Services';
import { HashLink } from 'react-router-hash-link';

const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    return (
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="blog" />
                                </div>
                                <p>
                                    Based in Houston, we bring the art of interior design to homes worldwide 
                                    through online projects. Our personalized approach creates functional, 
                                    beautiful spaces that tell unique stories for real people. 
                                </p>
                                <ul>
                                    <li>
                                        <a target="_blank" href="https://www.instagram.com/mvinterior.studio">
                                            <i className="ti-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.instagram.com/mvinterior.studio">
                                            <i className="ti-linkedin"></i>
                                        </a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Contact Us</h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <li><i className="fi flaticon-email"></i>
                                            mnoelvargas@gmail.com
                                        </li>
                                        <li><i className="fi flaticon-phone-call"></i>
                                            +1 (346) 855 0995
                                            <br />
                                            +598 99 930 319
                                        </li>
                                        <li><i className="fi flaticon-placeholder"></i>
                                            Houston, TX 77019, USA
                                            <br />
                                            Montevideo, Uruguay
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Top Services</h3>
                                </div>
                                <ul>
                                    {Services.slice(0, 6).map((service, Sitem) => (
                                        <li key={Sitem}>
                                            <HashLink onClick={ClickHandler} smooth to="/#service-section">
                                                {service.sTitle}
                                            </HashLink>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright"> 
                                Copyright &copy; 2024. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;