import React from 'react'
import Projects from '../../api/Projects'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom'
import SectionTitle from '../SectionTitle/SectionTitle';

const ProjectSectionS2 = (props) => {

    var settings = {
        dots: false,
        arrows: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className={`Arkitek-project-section-s2 ${props.prClass}`}>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-12">
                        <SectionTitle subTitle={'OUR Projects'} Title={'Latest Project We Designed'} />
                    </div>
                </div>
                <div className="project-wrap">
                    <div className="project-slider owl-carousel">
                        <Slider {...settings}>
                            {Projects.slice(0, 12).map((project, prj) => (
                                <div className="project-item" key={prj}>
                                    <div className="image">
                                        <img src={project.pImg} alt={project.Id} />
                                    </div>
                                    <div className="text" hidden>
                                        <h2>
                                            <Link onClick={ClickHandler} to={`/project-single/${project.slug}`}>
                                                {project.pTitle}
                                            </Link>
                                        </h2>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProjectSectionS2;