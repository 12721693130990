import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from '../../images/testimonial/img-1.jpg'
import ts2 from '../../images/testimonial/img-2.jpg'
import ts3 from '../../images/testimonial/img-3.jpg'
import quote from '../../images/testimonial/quote-2.png'
import SectionTitle from '../SectionTitle/SectionTitle';

const Testimonial2 = () => {

    var settings = {
        dots: true,
        arrows: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const testimonial = [
        {
            id: '01',
            tImg: ts1,
            Des: "Maria and her team were able to complete my Airbnb studio beyond and above my expectations. Working with them has been a professional and satisfying experience.",
            Title: 'Juan T.',
            Sub: "Montevideo, Uruguay",
        },
        {
            id: '02',
            tImg: ts2,
            Des: "Leaving my family room in her hands was the best decision I´ve ever made. Will be working with Maria in future projects.",
            Title: 'Jenn.',
            Sub: "Punta del Este, Uruguay",
        },
        {
            id: '03',
            tImg: ts3,
            Des: "Who said that working online is not possible? Maria delivered the project earlier than expected and it looks like an Instagram bedroom. I love coming home and spending time in my new room.",
            Title: 'Erika.',
            Sub: "CdMx, México",
        }
    ]

    return (

        <section className="Arkitek-testimonial-section-s2 section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <SectionTitle subTitle={'HAPPY CUSTOMERS'} Title={''} />
                    </div>
                </div>
                
                <div className="wpo-testimonial-wrap-s2">
                    <Slider {...settings}>
                        {testimonial.map((tesmnl, tsm) => (
                            <div className="item" key={tsm}>
                                <div className="testimonial-left-item" hidden>
                                    <div className="image">
                                        <img src={tesmnl.tImg} alt="" />
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="icon">
                                        <img src={quote} alt="" />
                                    </div>
                                    <p>{tesmnl.Des}</p>
                                    <h5>{tesmnl.Title}</h5>
                                    <span>{tesmnl.Sub}</span>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>

    );
}

export default Testimonial2;