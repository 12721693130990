import pImg1 from '../images/project/1-carrara.jpg'
import pImg2 from '../images/project/2-carrara.jpg'
import pImg3 from '../images/project/3-carrara.jpg'
import pImg4 from '../images/project/4-carrara.jpg'
import pImg5 from '../images/project/5-carrara.jpg'
import pImg6 from '../images/project/6-carrara.jpg'
import pImg7 from '../images/project/7-carrara.jpg'
import pImg8 from '../images/project/8-carrara.jpg'
import pImg9 from '../images/project/9-carrara.jpg'
import pImg10 from '../images/project/10-carrara.jpg'
import pImg11 from '../images/project/11-carrara.jpg'
import pImg12 from '../images/project/12-carrara.jpg'


import pSImg1 from '../images/project-single/img-1.jpg'
import pSImg2 from '../images/project-single/img-2.jpg'
import pSImg3 from '../images/project-single/img-3.jpg'
import pSImg4 from '../images/project-single/img-4.jpg'
import pSImg5 from '../images/project-single/img-5.jpg'
import pSImg6 from '../images/project-single/img-6.jpg'
import pSImg7 from '../images/project-single/img-7.jpg'

import sSingleimg1 from '../images/project-single/2.jpg'
import sSingleimg2 from '../images/project-single/3.jpg'


const Projects = [
    {
        Id: '1',
        pTitle: '', 
        slug: '',
        pImg:pImg1,
        pSImg:pSImg1,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '2',
        pTitle: '', 
        slug: '',
        pImg:pImg2,
        pSImg:pSImg2,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        pTitle: '', 
        slug: '',
        pImg:pImg3,
        pSImg:pSImg3,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        pTitle: '', 
        slug: '',
        pImg:pImg4,
        pSImg:pSImg4,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '5',
        pTitle: '', 
        slug: '',
        pImg:pImg5,
        pSImg:pSImg5,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '6',
        pTitle: '', 
        slug: '',
        pImg:pImg6,
        pSImg:pSImg6,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '7',
        pTitle: '', 
        slug: '',
        pImg:pImg7,
        pSImg:pSImg7,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '8',
        pTitle: '', 
        slug: '',
        pImg:pImg8,
        pSImg:pSImg6,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '9',
        pTitle: '', 
        slug: '',
        pImg:pImg9,
        pSImg:pSImg6,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '10',
        pTitle: '', 
        slug: '',
        pImg:pImg10,
        pSImg:pSImg6,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '11',
        pTitle: '', 
        slug: '',
        pImg:pImg11,
        pSImg:pSImg6,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '12',
        pTitle: '', 
        slug: '',
        pImg:pImg12,
        pSImg:pSImg6,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
]

export default Projects;