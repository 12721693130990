import simg from '../images/service/icon-1.svg'
import simg2 from '../images/service/icon-2.svg'
import simg3 from '../images/service/icon-3.svg'
import simg4 from '../images/service/icon-4.svg'
import simg5 from '../images/service/icon-5.svg'
import simg6 from '../images/service/icon-6.svg'

import scimg from '../images/service/1.svg'
import scimg2 from '../images/service/2.svg'
import scimg3 from '../images/service/3.svg'
import scimg4 from '../images/service/4.svg'
import scimg5 from '../images/service/5.svg'
import scimg6 from '../images/service/6.svg'

import sSimg1 from '../images/service-single/img-1.jpg'
import sSimg2 from '../images/service-single/img-2.jpg'
import sSimg3 from '../images/service-single/img-3.jpg'
import sSimg4 from '../images/service-single/img-4.jpg'
import sSimg5 from '../images/service-single/img-5.jpg'
import sSimg6 from '../images/service-single/img-6.jpg'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'

const Services = [
    {
        Id: '5',
        sImg:simg5,
        scImg:scimg5,
        sSimg:sSimg5,
        sTitle: 'In-Home Design',
        slug: 'in-home-design',
        description:'Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac',
        des2:'Let’s meet at your home to discuss your project goals.  Our team will create a space that is uniquely yours.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '1',
        sImg:simg,
        scImg:scimg,
        sSimg:sSimg1,
        sTitle: 'Online Service',
        slug: 'online-service',
        description:'Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac',
        des2:'Transform your spaces into personalized heaven, no matter where you are in the world.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '6',
        sImg:simg6,
        scImg:scimg6,
        sSimg:sSimg6,
        sTitle: 'Remodeling',
        slug: 'remodeling',
        description:'Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac',
        des2:'Size doesn’t matter: from single rooms to full-scale remodel.  We plan, manage and execute your project.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImg:simg3,
        scImg:scimg3,
        sSimg:sSimg3,
        sTitle: 'Relocation Service',
        slug: 'relocation-service',
        description:'Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac',
        des2:'New city. New home. Check out our packages. They fit in any budget.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        sImg:simg4,
        scImg:scimg4,
        sSimg:sSimg4,
        sTitle: 'Short Term Rental Design',
        slug: 'short-term-rental-design',
        description:'Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac',
        des2:'Turn your Airbnb into a cozy home-away-from-home and bring in much higher profits.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '2',
        sImg:simg2,
        scImg:scimg2,
        sSimg:sSimg2,
        sTitle: 'Custom Furniture',
        slug: 'Custom-Solutions',
        description:'Noimy donecing and vivamus vitae commodo. Ut purus ornare laoreet ultrices are sitac',
        des2:'Get all the recommendations to get your one-of-a-kind piece. Our service offers tailored solutions for your spaces.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    
]
export default Services;